// ColorPicker.js
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { ChromePicker } from 'react-color';
import { FaPalette } from 'react-icons/fa';
import './ColorPicker.scss';


const ForwardedFaPalette = forwardRef((props, ref) => (
    <div ref={ref} onClick={props.onClick}>
      <FaPalette className="color-picker-icon" />
    </div>
  ));

const ColorPicker = ({ color, onChange, defaultColor }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const colorPickerRef = useRef(null);
    const iconRef = useRef(null);
  
    const handleClick = () => {
      setDisplayColorPicker(!displayColorPicker);
    };

  const handleClose = (e) => {
    if (iconRef.current && iconRef.current.contains(e.target)) {
      // Do nothing if the icon is clicked
      return;
    }
    if (colorPickerRef.current && !colorPickerRef.current.contains(e.target)) {
      setDisplayColorPicker(false);
    }
  };

  const handleReset = () => {
    onChange(defaultColor);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
    };
  }, []);

  const handleChange = (color) => {
    onChange(color.hex);
  };

  return (
    <div className="color-picker-container">
      <ForwardedFaPalette onClick={handleClick} ref={iconRef} />
      {displayColorPicker ? (
        <div className="color-picker" ref={colorPickerRef}>
          <ChromePicker color={color} onChange={handleChange} />
          <button onClick={handleReset}>Reset to Default</button>
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
