import React, { useState, useEffect } from 'react';
import './Header.scss';
import { Link, animateScroll as scroll } from 'react-scroll';
import ColorPicker from './ColorPicker';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [accentColor, setAccentColor] = useState('#038af2');
  const defaultColor = '#038af2';

  const handleColorChange = (color) => {
    setAccentColor(color);
    document.documentElement.style.setProperty('--accent-color', color);
  };

  return (
    <header id="header" className={scrolled ? 'sticky' : ''}>
      <div className="container-fluid">
      <div id="logo">
          <h1><Link to="landingpage" smooth={true} duration={1000} offset={-70}>Dylan Adams-Hegarty</Link></h1>
        </div>
        <nav id="nav-menu-container" className={menuOpen ? 'open' : ''}>
          <ul className="nav-menu">
            <li className="menu-active"><Link to="landingpage" smooth={true} duration={1000} offset={-70}>Home</Link></li>
            <li><Link to="services" smooth={true} duration={1000} offset={-70}>Services</Link></li>
            <li><Link to="portfolio" smooth={true} duration={1000} offset={-70}>Portfolio</Link></li>
            <li><Link to="process" smooth={true} duration={1000} offset={-70}>Design Process</Link></li>
            <li><Link to="contact" smooth={true} duration={1000} offset={-70}>Contact</Link></li>
            <li><ColorPicker color={accentColor} onChange={handleColorChange} defaultColor={defaultColor} /></li>
          </ul>
        </nav>

        <div className="menu-icon" onClick={toggleMenu}>
          <i className="ion-ios-menu" style={{ fontSize: '32px', color: 'white' }}></i>
        </div>
      </div>
    </header>
  );
};

export default Header;
