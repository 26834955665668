import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Sidebar from './Sidebar';
import PortfolioItem from './PortfolioItem';
import './Portfolio.scss'

import image1 from '../Images/frontendDev/PhastekPerformance.webp';
import backgroundImage1 from '../Images/frontendDev/PhastekPerformance1.png';
import image2 from '../Images/frontendDev/ProAudio-com.webp';
import backgroundImage2 from '../Images/frontendDev/ProAudio-com1.png';
import image3 from '../Images/frontendDev/UnisanDirect.webp';
import backgroundImage3 from '../Images/frontendDev/UnisanDirect1.png';
import image4 from '../Images/frontendDev/GlowbackLEDStore.webp';
import backgroundImage4 from '../Images/frontendDev/GlowbackLEDStore1.png';
import image5 from '../Images/frontendDev/ActionVillage.webp';
import backgroundImage5 from '../Images/frontendDev/ActionVillage1.png';
import image6 from '../Images/frontendDev/MeddMax.webp'
import backgroundImage6 from '../Images/frontendDev/MeddMax1.png'

import designImage1 from '../Images/digitalDesign/worlds-min.png'
import designImage2 from '../Images/digitalDesign/elephant-min.png'
import designImage3 from '../Images/digitalDesign/fall-min.png'
import designImage4 from '../Images/digitalDesign/spring-min.png'
import designImage5 from '../Images/digitalDesign/philadelphia-min.png'
import designImage6 from '../Images/digitalDesign/owl-min.png'
import designImage7 from '../Images/digitalDesign/halloween-min.png'
import designImage8 from '../Images/digitalDesign/digitalSpace-min.png'
import designImage9 from '../Images/digitalDesign/backflip-min.png'
import designImage10 from '../Images/digitalDesign/stardust.gif'
import designImage11 from '../Images/digitalDesign/contrastmax-min.png'

import Zoom from 'react-reveal/Zoom';

const Portfolio = ({ setSelectedImage, activeTab, setActiveTab, background }) => {
   
    const frontendItems = [
        {
          id: 1,
          title: "Phastek",
          subtitle: "https://www.phastekperformance.com",
          description: "I had the privilege of serving as a front-end developer on a range of pivotal projects at MAK Digital Design. My role was not just limited to coding; I played also played a role in shaping the visual and interactive aspects of our web applications. I was deeply involved in the front-end development process, ensuring that our websites were not only functional but also aesthetically pleasing and user-friendly. Beyond the technicalities, I collaborated closely with the design team to enhance the overall design, ensuring that the user experience (UX) was intuitive and that the user interface (UI) was both engaging and efficient. My contributions were instrumental in driving the success of our projects, blending technical expertise with a keen design sensibility.",
          link: "https://www.phastekperformance.com",
          image: image1,
          backgroundImage: backgroundImage1
        },
        {
          id: 2,
          title: "ProAudio",
          subtitle: "https://proaudio.com",
          description: "I had the privilege of serving as a front-end developer on a range of pivotal projects at MAK Digital Design. My role was not just limited to coding; I played also played a role in shaping the visual and interactive aspects of our web applications. I was deeply involved in the front-end development process, ensuring that our websites were not only functional but also aesthetically pleasing and user-friendly. Beyond the technicalities, I collaborated closely with the design team to enhance the overall design, ensuring that the user experience (UX) was intuitive and that the user interface (UI) was both engaging and efficient. My contributions were instrumental in driving the success of our projects, blending technical expertise with a keen design sensibility.",
          link: "https://proaudio.com/",
          image: image2,
          backgroundImage: backgroundImage2
        },
        {
          id: 3,
          title: "Unisan",
          subtitle: "https://unisandirect.com",
          description: "I had the privilege of serving as a front-end developer on a range of pivotal projects at MAK Digital Design. My role was not just limited to coding; I played also played a role in shaping the visual and interactive aspects of our web applications. I was deeply involved in the front-end development process, ensuring that our websites were not only functional but also aesthetically pleasing and user-friendly. Beyond the technicalities, I collaborated closely with the design team to enhance the overall design, ensuring that the user experience (UX) was intuitive and that the user interface (UI) was both engaging and efficient. My contributions were instrumental in driving the success of our projects, blending technical expertise with a keen design sensibility.",
          link: "https://unisandirect.com/",
          image: image3,
          backgroundImage: backgroundImage3
        },
        {
          id: 4,
          title: "GlowbackLED",
          subtitle: "https://www.glowbackledstore.com",
          description: "I had the privilege of serving as a front-end developer on a range of pivotal projects at MAK Digital Design. My role was not just limited to coding; I played also played a role in shaping the visual and interactive aspects of our web applications. I was deeply involved in the front-end development process, ensuring that our websites were not only functional but also aesthetically pleasing and user-friendly. Beyond the technicalities, I collaborated closely with the design team to enhance the overall design, ensuring that the user experience (UX) was intuitive and that the user interface (UI) was both engaging and efficient. My contributions were instrumental in driving the success of our projects, blending technical expertise with a keen design sensibility.",
          link: "https://www.glowbackledstore.com/",
          image: image4,
          backgroundImage: backgroundImage4
        },
      
        {
          id: 5,
          title: "ActionVillage",
          subtitle: "https://www.actionvillage.com",
          description: "I had the privilege of serving as a front-end developer on a range of pivotal projects at MAK Digital Design. My role was not just limited to coding; I played also played a role in shaping the visual and interactive aspects of our web applications. I was deeply involved in the front-end development process, ensuring that our websites were not only functional but also aesthetically pleasing and user-friendly. Beyond the technicalities, I collaborated closely with the design team to enhance the overall design, ensuring that the user experience (UX) was intuitive and that the user interface (UI) was both engaging and efficient. My contributions were instrumental in driving the success of our projects, blending technical expertise with a keen design sensibility.",
          link: "https://www.actionvillage.com",
          image:image5,
          backgroundImage: backgroundImage5
        },
    
        {
          id: 6,
          title: "MeddMax",
          subtitle: "https://meddmax.com",
          description: "I had the privilege of serving as a front-end developer on a range of pivotal projects at MAK Digital Design. My role was not just limited to coding; I played also played a role in shaping the visual and interactive aspects of our web applications. I was deeply involved in the front-end development process, ensuring that our websites were not only functional but also aesthetically pleasing and user-friendly. Beyond the technicalities, I collaborated closely with the design team to enhance the overall design, ensuring that the user experience (UX) was intuitive and that the user interface (UI) was both engaging and efficient. My contributions were instrumental in driving the success of our projects, blending technical expertise with a keen design sensibility.",
          link: "https://meddmax.com/",
          image:image6,
          backgroundImage: backgroundImage6
        }
      ];
    
      const designItems = [
        {
          id: 1,
          title: "Cosmic Evolution",
          subtitle: "We are the representatives of the cosmos; we are an example of what hydrogen atoms can do, given 15 billion years of cosmic evolution.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage10,
          backgroundImage: designImage10
        },
        {
          id: 2,
          title: "Falling Worlds",
          subtitle: "There are two worlds. The world you understand and the world you don't. These worlds exist side by side, sometimes only centimeters apart, and the great majority of people spend their entire lives in one without being aware of the other.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage1,
          backgroundImage: designImage1
        },
        {
          id: 3,
          title: "Elephants",
          subtitle: "Nature's great masterpiece, an elephant - the only harmless great thing",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage2,
          backgroundImage: designImage2
        },
        {
          id: 4,
          title: "Fall",
          subtitle: "Now Autumn's fire burns slowly along the woods and day by day the dead leaves fall and melt.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage3,
          backgroundImage: designImage3
        },
    
        {
          id: 5,
          title: "Spring",
          subtitle: "Our world exisits in a perfect balance of ppopsitive and negative forces, and tthese forces constantly flow forward inn the cycle of the seasons. The season of springg is  about rebirth and regeneration, and it ius the perfect metaphor and reminder that no matter how difficult life may feel, there is a new season of rebirth right around the corner.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage4,
          backgroundImage: designImage4
        },
      
        {
          id: 6,
          title: "Philadelphia",
          subtitle: "The city of Brotherly Love",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage5,
          backgroundImage: designImage5
        },
    
        {
          id: 7,
          title: "Owl",
          subtitle: "The owl, one of the most curious creatures. A bird that stays awake during the night. What does he see and what does he know that the rest of the world is missing",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage6,
          backgroundImage: designImage6
        },
    
        {
          id: 8,
          title: "Halloween",
          subtitle: "We make up horrors to help us cope with the real ones.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage7,
          backgroundImage: designImage7
        },
    
        {
          id: 9,
          title: "Linear Moons",
          subtitle: "Inspired by Tipper who preformed an amazing live experience at Kings theather in New York.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage8,
          backgroundImage: designImage8
        },
    
        {
          id: 10,
          title: "Jump",
          subtitle: "Those who never jump, never fly.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage9,
          backgroundImage: designImage9
        },
    
        {
          id:  10,
          title: "Contrasting Seasons",
          subtitle: "Perspective gives us the ability to accurately contrast the large with the small,  and the important with the less important. Without  it we are lost in a world where all ideas, news, and information look the same. We cannot differentiate, we cannot prioritize, and we cannot make good choices.",
          description: "In the Digital Design tab, each image tells its own story. Crafted with Adobe Photoshop, they're a blend of about 20 different elements and images. I've pieced them together, using various Photoshop techniques, to create a unique and cohesive visual. It's a fun challenge, merging different bits into one harmonious design!",
          link: "https://www.instagram.com/dylsigns/",
          image: designImage11,
          backgroundImage: designImage11
        },
    
        
        
      ];
    const items = activeTab === 'frontend' ? frontendItems : designItems;
    const [selectedItem, setSelectedItem] = useState(items[0]);


    useEffect(() => {
        setSelectedImage(selectedItem.backgroundImage);
    }, [selectedItem, setSelectedImage]);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      afterChange: (currentSlide) => {
        setSelectedItem(items[currentSlide]);
      },
      responsive: [ // Notice the array here
      {
        breakpoint: 2000, // At 2000px and below
        settings: {
          slidesToShow: 3, // Show only 3 slides
          slidesToScroll: 1
        }
      },

      {
        breakpoint: 1500, // At 2000px and below
        settings: {
          slidesToShow: 2, // Show only 3 slides
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800, // At 2000px and below
        settings: {
          slidesToShow: 1, // Show only 3 slides
          slidesToScroll: 1
        }
      }
    ]
      
    };

    return (
      <Zoom top duration={1500}>
        <div className="portfolio" style={{ backgroundImage: `url(${background})` }}>
          
          {/* Tab Navigation */}
          <div className="tabs">
            <button 
                className={activeTab === 'frontend' ? 'active' : ''} 
                onClick={() => setActiveTab('frontend')}>
                Front-end Development
            </button>
            <button 
                className={activeTab === 'design' ? 'active' : ''} 
                onClick={() => setActiveTab('design')}>
                Digital Design
            </button>
          </div>
  
          <Sidebar {...selectedItem} />
          <div className="portfolio-items">
            <Slider {...settings}>
              {items.map(item => (
                  <PortfolioItem
                    key={item.id}
                    {...item}
                    isActive={item.id === selectedItem.id}
                    onClick={() => {
                      setSelectedItem(item);
                      setSelectedImage(item.image);
                    }}
                  />
              ))}
            </Slider>
          </div>
        </div>
        </Zoom>
      );
  };

export default Portfolio;
