import React from 'react';
import './Footer.scss';

const Footer = ({ title, subtitle, description, link }) => {
    return (
        <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-info">
                <h3>Dylan Adams-Hegarty</h3>
                <p>
                  For any questions, comments, or inquires please fill out the
                  contact form above!
                </p>
              </div>
  
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="ion-ios-arrow-right"></i>
                    <a href="https://www.instagram.com/dylsigns/" target="_blank">Instagram</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-right"></i>
                    <a href="https://www.linkedin.com/in/dylan-adams-hegarty-50510b160/" target="_blank">LinkedIn</a>
                  </li>
                  <li>
                    <i className="ion-ios-arrow-right"></i>
                    <a href="">Coming soon</a>
                  </li>
                </ul>
              </div>
  
              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>Contact</h4>
                <p>
                  Philadelphia, PA 19128<br />
                  Marlton, NJ 08053<br />
                  United States <br />
                  <strong>Phone:</strong> +1 856-669-4756<br />
                  <strong>Email:</strong> Dylanadamshegarty@gmail.com<br />
                </p>
              </div>
  
              <div className="col-lg-3 col-md-6 footer-newsletter">
                <h4>About Myself</h4>
                <p>
                  Aspiring Front End Developer / Designer
                  seeking a job in Philadelphia or New Jersey. Some hobbies
                  include traveling, seeing friends, and skateboarding. Graduated
                  from Thomas Jefferson University.
                  
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong>Dylan Adams-Hegarty</strong>. All Rights
            Reserved
          </div>
        </div>
      </footer>
    );
  };

export default Footer;
