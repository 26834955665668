import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Import the up arrow icon from Font Awesome

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {  // Show button after scrolling 200px
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    showButton && (
      <button
        className='back-to-top'
        onClick={scrollToTop}
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: '100',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FaArrowUp color="white" />
      </button>
    )
  );
};

export default ScrollToTopButton;
