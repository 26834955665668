import React from 'react';
import './Sidebar.scss';

const Sidebar = ({ title, subtitle, description, link }) => {
    return (
      <div className="sidebar">
        <h2>{title}</h2>
        <h4>{subtitle}</h4>
        <p>{description}</p>
        <a href={link} target="_blank">Learn More</a>
      </div>
    );
  };

export default Sidebar;
