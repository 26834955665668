import React from 'react';
import './Services.scss';
import Dev from '../Images/services/dev.png'
import UX from '../Images/services/uxtry.png'
import DD from '../Images/services/dd.png'
import '../lib/ionicons/css/ionicons.min.css'
import '../lib/animate/animate.min.css'
import '../lib/animate/animate.css';
import Slide from 'react-reveal/Slide';


const Services = () => {
  
    return (
        <section id="services">
        <div className="container">
          <header className="section-header">
            <h3>Services</h3>
            <p className="section-description">

              I offer an expansive range of services, encompassing front-end development, UX/UI design, and advanced digital design solutions.
            </p>
          </header>

          <div className="row about-cols">
          <Slide left>
            <div className="col-md-4 wow fadeInUp">
              <div className="about-col">
                <div className="img">
                  <img id="service-picture" src={Dev} alt="front-end-development" className="img-fluid"/>
                </div>
                <div className="icon">
                  <i className="ion-ios-speedometer-outline"></i>
                </div>
                <h2 className="title"><span>Front End Development</span></h2>
                <p>
                  Crafting captivating websites that leave a lasting impression. From minimalist static pages to dynamic e-commerce businesses generating millions in sales, my expertise spans a broad spectrum. Utilizing diverse frameworks and CMS platforms, I ensure each product exceeds expectations.
                </p>
              </div>
            </div>
          </Slide>
          <Slide bottom>
            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="about-col">
                <div className="img">
                  <img src={UX} alt="" className="img-fluid" />
                </div>
                <div className="icon">
                  <i className="ion-ios-list-outline"></i>
                </div>
                <h2 className="title"><span>UX / UI Design</span></h2>
                <p>
                  Crafting superior user experiences for websites and apps is my forte. I keenly identify areas needing enhancement, delve deep into genuine user feedback, and ingeniously address these gaps. This meticulous approach transforms and elevates the overall user journey, regardless of the service or product.
                </p>
              </div>
            </div>
            </Slide>
            <Slide right>
            <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
              <div className="about-col">
                <div className="img">
                  <img src={DD} alt="" className="img-fluid" />
                </div>
                <div className="icon">
                  <i className="ion-ios-eye-outline"></i>
                </div>
                <h2 className="title"><span>Digital Design</span></h2>
                <p>
                  Harnessing the power of the Adobe suite and select software, I provide comprehensive digital design solutions, from diverse artwork to corporate graphic designs. Each creation undergoes rigorous evaluation and refinement, guaranteeing satisfaction for both clients and their target audience.
                </p>
              </div>
            </div>
            </Slide>
          </div>
        </div>
      </section>
    );
  };

export default Services;
