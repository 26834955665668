import React, { useCallback, useState } from 'react';

import './App.scss';
import './lib/bootstrap/css/bootstrap.min.css'

import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particlesOptions from "./particles.json";

import Header from './Components/Header';
import LandingPage from './Components/LandingPage';
import Services from './Components/Services'
import Portfolio from './Components/Portfolio';
import Parallax1 from './Components/Parallax1';
import DesignProcess from './Components/DesignProcess';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import BackToTop from './Components/BackToTop'

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main); 
    }, [])

    const [selectedImage, setSelectedImage] = useState(null);
    const [activeTab, setActiveTab] = useState('frontend');




    return (
        <div className="App">

            <Particles options={particlesOptions} init={particlesInit}/>

            <Header />
            <LandingPage />
            <Services />
            <Portfolio 
                setSelectedImage={setSelectedImage} 
                activeTab={activeTab} 
                setActiveTab={setActiveTab}
                background={selectedImage}
            />
            <Parallax1 />
            <DesignProcess />
            <Contact />
            <Footer />
            <BackToTop />
        </div>
    );
}

export default App;
