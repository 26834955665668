import React from 'react';
import './Parallax1.scss';
import BClogo from '../Images/bigcom/big.png'
import Cert from '../Images/bigcom/bigg.png'
import '../Images/bigcom/project-bg.png'

const Parallax1 = () => {
  
    return (
        <div id="Parallax1">
            <div className="wrapper">
                <img className="one" src={BClogo} alt="Bigcommerce Certified Developer"/>
                <img className="two" src={Cert} alt="Bigcommerce Certified Developer"/>
            </div>
        </div>
    );
};

export default Parallax1;
