import React from 'react';
import './LandingPage.scss';
import Flip from 'react-reveal/Flip';
import Bounce from  'react-reveal/Bounce';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin} from 'react-icons/fa';
import { BsChevronDoubleDown } from "react-icons/bs";
import { Link, animateScroll as scroll } from 'react-scroll';
import 'animate.css'
const LandingPage = () => {
  return (
      <section className='landing-page-container' id="landingpage">
          <div className='landing-page'>
            <Flip bottom opposite>
              <h2 className='name'>Dylan Adams-Hegarty</h2>
            </Flip>

            <div className='text-wrapper'>
              <h2 className='mobile-text'>Interactive Design & Development</h2>
              <h2 className='intro-1 slide-right'>Interactive Design</h2>
              <h2 className='intro-2 slide-left'>& Development</h2>
            </div>

            <Flip top>
            <div className='socials'>
              <a href="#"><FaFacebook size={26} /></a>
              <a href="#"><FaTwitter size={26} /></a>
              <a href="#"><FaInstagram size={26} /></a>
              <a href="#"><FaLinkedin size={26} /></a>
            </div>
            </Flip>

            <Bounce top>
              <div className='arrow-down animate__bounce'>
                <Link to="services" smooth={true} duration={1000} offset={-70}>
                  <BsChevronDoubleDown size={75}/>
                </Link>
              </div>
            </Bounce>
          </div>
      </section>
  );
}


export default LandingPage;
