import React, { useState } from 'react';
import './Contact.scss';
import Slide from 'react-reveal/Slide';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!formData.name) newErrors.name = "Name is required!";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Valid email is required!";
    if (!formData.message || formData.message.length < 10) newErrors.message = "Message should be at least 10 characters long!";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // No errors, submit the form
      e.target.submit();
    }
  };

  return (
    <div className='contact' id="contact">
        <div className="container">

            <div className="section-header">
                <h3>Contact</h3>
                <p>
                    For any questions, comments, or inquires please fill out the
                    contact form below.
                </p>
            </div>
            <Slide top>
            <div className="contact-info">
                <div className="col-md-4">
                    <div className="contact-address">
                    <i className="ion-ios-location-outline"></i>
                    <h3>Address</h3>
                    <address>Philadelphia, PA, 19128</address>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="contact-phone">
                    <i className="ion-ios-telephone-outline"></i>
                    <h3>Phone Number</h3>
                    <p><a href="tel:+155895548855">+1 856-669-4756</a></p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="contact-email">
                    <i className="ion-ios-email-outline"></i>
                    <h3>Email</h3>
                    <p>
                        <a href="mailto:dylanadamshegarty@gmail.com"
                        >dylanadamshegarty@gmail.com</a
                        >
                    </p>
                    </div>
                </div>
            </div>
            </Slide>
            <Slide bottom delay={200}>
            <form className='contact-form' action="https://getform.io/f/6b8551a1-9f56-4ea2-8317-c396d76bf837" method="POST" onSubmit={handleSubmit}>
                <div className='contact-section-one'>
                    <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} />
                    {errors.name && <p className="error">{errors.name}</p>}

                    <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>

                <div className='contact-section-two'>
                <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} />
                {errors.message && <p className="error">{errors.message}</p>}
                </div>
                <div className="button">
                    <button type="submit">Submit</button>
                </div>
            </form>
            </Slide>
        </div>      
    </div>
  );
};

export default Contact;
