import React from 'react';
import './DesignProcess.scss';
import Process1 from '../Images/designProcess/client.png'
import Process2 from '../Images/designProcess/wireframe.png'
import Process3 from '../Images/designProcess/usertest.png'
import Process4 from '../Images/designProcess/mockup.png'
import Process5 from '../Images/designProcess/revisions.png'
import Process6 from '../Images/designProcess/final.png'
import Bounce from 'react-reveal/Bounce';


const DesignProcess = () => {
    return (
<section id="process">
    <div className="container">
        <div className="section-header">
            <h3>Design Process</h3>
            <p className="process-description">
            This process allows me to design and develop a quality product
            that a client and their users will love.
            </p>
        </div>

  <div className="row">
  <Bounce top duration={1300} delay={0}>
    <div className="span3">
      <div className="service-box wow fadeInUp" id="serviceanchor">
        <img
          src={Process1}
          alt="Front End Development Icon"
        />
        <h2>1. Client's Vision</h2>
        <p>Initiating our journey with the client's vision, I delve deep into their goals. Through brainstorming and sketches, a preliminary draft emerges, setting the foundation for what's to come.</p>
      </div>
    </div>
    </Bounce>
    <Bounce top duration={1300} delay={200}>
    <div className="span3">
      <div className="service-box wow fadeInUp" data-wow-delay="0.1s">
        <img
          className="processpic"
          src={Process2}
          alt="User Experience Icon"
        />
        <h2>2. Wireframes</h2>
        <p>Transitioning from ideas to structure, I craft wireframes. These blueprints, refined through critiques, ensure alignment with the initial objectives, paving the way for user interaction.</p>
      </div>
    </div>
    </Bounce>
    <Bounce top duration={1300} delay={400}>
    <div className="span3">
      <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
        <img src={Process3} alt="Digital Design Icon" />
        <h2>3. User Testing</h2>
        <p>Engaging real users, I test the design's efficacy. Their interactions guide refinements, ensuring a seamless and positive experience. Their feedback becomes the compass for the next phase.</p>
      </div>
    </div>
    </Bounce>
    <Bounce top duration={1300} delay={600}>
    <div className="span3">
      <div className="service-box wow fadeInUp" data-wow-delay="0.3s">
        <img src={Process4} alt="Digital Design Icon" />
        <h2>4. Mockup</h2>
        <p>Armed with insights from user testing, I enhance design and user experience, aligning with client specifications. This refined mock-up, a near-final vision, is then presented to the client.</p>
      </div>
    </div>
    </Bounce>
    <Bounce top duration={1300} delay={800}>
    <div className="span3">
      <div className="service-box wow fadeInUp" data-wow-delay="0.4s">
        <img src={Process5} alt="Digital Design Icon" />
        <h2>5. Revisions</h2>
        <p>Perfection is a journey. We integrate the client's final feedback, ensuring cross-platform consistency. As we fine-tune, our focus sharpens on preparing the design for its grand launch.</p>
      </div>
    </div>
    </Bounce>
    <Bounce top duration={1300} delay={1000}>
    <div className="span3">
      <div className="service-box  wow fadeInUp" data-wow-delay="0.5s">
        <img src={Process6} alt="Digital Design Icon" />
        <h2>6. Final Product</h2>
        <p>Revelation time! The client witnesses their vision live. We eagerly await feedback, celebrating our triumphs and noting areas for growth. Every critique fuels my passion for the next venture.</p>
      </div>
    </div>
    </Bounce>
  </div>
</div>
</section>
    );
  };

export default DesignProcess;


