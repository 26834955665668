import React from 'react';

const PortfolioItem = ({ image, title, onClick, isActive }) => {
  return (
    <div 
      className={`portfolio-item ${isActive ? 'active' : ''}`} 
      style={{ backgroundImage: `url(${image})` }} 
      onClick={onClick}
    >
      <span></span>
      <h3>{title}</h3>
    </div>
  );
};

  
export default PortfolioItem;
